import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import "./styles.css"
import { isDesktop } from 'react-device-detect';
import WpMainTitle from '../../svgs/WpMainTitle';

export default function Main() {
    
    var onHelpClick = () => {
        window.open("https://t.me/whypnbot", "_blank")
    }

    return (
        <div className={"main"}>
            <div className={"main__sub"} style={{ margin: "auto", textAlign: 'left' }}>
                {
                    isDesktop
                        ?
                        <Container maxWidth="lg" >
                            <WpMainTitle></WpMainTitle>
                            <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                            <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                        </Container>
                        :
                        <Container maxWidth="lg" >
                            <Typography variant="h1" fontWeight={900} color={"white"} className='main__name' gutterBottom>WhyPN</Typography>
                            <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                            <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                        </Container>
                }


                {/*  <Container maxWidth="lg" >
                    <p className='main__name' >WhyPN</p>
                    <p  className='main__subname' >твой Интернет-щит</p>
                    <Button sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                </Container> */}
   
            </div>
        </div>
    );
}


